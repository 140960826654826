
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiShoppingBag, FiPackage } from "react-icons/fi";
import { BiMessageSquareDetail } from "react-icons/bi";
import axios from "axios";
import { server } from "../../server";  
import { toast } from "react-toastify";
import logo from "../../Assests/images/logoo.png";

const message = JSON.parse(localStorage.getItem("allMessage"));
const unreadMessage = JSON.parse(localStorage.getItem("unreadMessage"));


const AdminHeader = () => {
  const { user } = useSelector((state) => state.user);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For controlling dropdown visibility
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {

    axios.get(`${server}/user/logout`, {
      withCredentials: true,
    })
    .then((res) => {
      toast.success(res.data.message);
      window.location.reload();
      
    })
    .catch((error) => {
      console.log('error', error)
      toast.error(error.response.data.message);
    });

  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Link to="/dashboard/coupons" className="hidden 800px:block">
            <AiOutlineGift color="#555" size={30} className="mx-5 cursor-pointer" />
          </Link>
          <Link to="/dashboard-events" className="hidden 800px:block">
            <MdOutlineLocalOffer color="#555" size={30} className="mx-5 cursor-pointer" />
          </Link>
          <Link to="/dashboard-products" className="hidden 800px:block">
            <FiShoppingBag color="#555" size={30} className="mx-5 cursor-pointer" />
          </Link>
          <Link to="/dashboard-orders" className="hidden 800px:block">
            <FiPackage color="#555" size={30} className="mx-5 cursor-pointer" />
          </Link>
          <Link to="/dashboard-messages" className="hidden 800px:block relative">
            <BiMessageSquareDetail color="#555" size={30} className="mx-5 cursor-pointer" />
            <span className="absolute right-0 top-0 rounded-full bg-orange-400 w-4 h-4 p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
              {/* Add unread message count */}
              {user?.unreadMessages || 0}
            </span>
          </Link>
        </div>

        {/* Account Avatar and Dropdown */}
        <div className="relative">
          <img
            src={user?.avatar?.url || "/assets/images/default-avatar.png"}
            alt="User Avatar"
            className="w-[50px] h-[50px] rounded-full object-cover cursor-pointer"
            onClick={toggleDropdown}
          />

          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-[150px] bg-white border border-gray-200 rounded-lg shadow-lg z-50">
              <Link
                to="/profile"
                className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                onClick={() => setIsDropdownOpen(false)}
              >
                Profile
              </Link>
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
