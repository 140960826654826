import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlus, FaTags } from 'react-icons/fa';
import Select from 'react-select';

const QuantityPriceInput = ({ onPriceChange, currency, initialData = [] }) => {
  // If there's initial data, use it; otherwise, set default empty range.
  const [ranges, setRanges] = useState(initialData.length > 0 ? initialData : [{ min: '', max: '', price: '', discountPrice: '', metric: '' }]);
  const [errors, setErrors] = useState([]);
  const [priceError, setPriceError] = useState(false);

  const options = [
    { value: 'kilogram', label: 'Kg' },
    { value: 'litre', label: 'Litres' },
    { value: 'piece', label: 'Pieces' },
    { value: 'gram', label: 'Grams' },
    { value: 'millilitre', label: 'Millilitres' },
    { value: 'ton', label: 'Tons' },
    { value: 'cubic_meter', label: 'Cubic Meters' },
    { value: 'cubic_centimeter', label: 'Cubic Centimeters' },
    { value: 'cubic_feet', label: 'Cubic Feet' },
    { value: 'pound', label: 'Pounds' },
    { value: 'ounce', label: 'Ounces' },
    { value: 'gallon', label: 'Gallons' },
    { value: 'quart', label: 'Quarts' },
    { value: 'barrel', label: 'Barrels' },
    { value: 'bundle', label: 'Bundles' },
    { value: 'dozen', label: 'Dozens' },
    { value: 'box', label: 'Boxes' },
    { value: 'pallet', label: 'Pallets' },
  ];

  // Handle changes to the select dropdown for metric
  const handleChange = (selectedOption, index) => {
    const newRanges = [...ranges];
    newRanges[index].metric = selectedOption.value;
    setRanges(newRanges);
    onPriceChange(newRanges);  // Pass updated ranges to parent
  };

  // Handle changes to input fields like min, max, price, discountPrice
  const handleRangeChange = (index, field, value) => {
    const newRanges = [...ranges];
    newRanges[index][field] = value;
    setRanges(newRanges);

    // Validation checks
    const errorsArray = [];

    if (Number(newRanges[index].min) >= Number(newRanges[index].max)) {
      errorsArray.push('Min should be less than Max.');
    }
    if (Number(newRanges[index].min) <= 0) {
      errorsArray.push('Min should be greater than 0.');
    }
    if (Number(newRanges[index].discountPrice) !== '' && Number(newRanges[index].discountPrice) >= Number(newRanges[index].price)) {
      errorsArray.push('Discount Price should be less than Price.');
    }

    // Update errors and propagate change
    setErrors(errorsArray);
    setPriceError(errorsArray.length > 0);
    onPriceChange(newRanges);  // Pass updated ranges to parent
  };

  const addRange = () => {
    if (errors.length > 0) {
      setPriceError(true);
      return;
    }

    const newRange = { min: '', max: '', price: '', discountPrice: '', metric: '' };
    setRanges([...ranges, newRange]);
    setErrors([...errors, []]);
    setPriceError(false);
  };

  const removeRange = (index) => {
    const newRanges = [...ranges];
    newRanges.splice(index, 1);
    setRanges(newRanges);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);

    setPriceError(newErrors.length > 0);
    onPriceChange(newRanges);  // Pass updated ranges to parent
  };

  // If you have `initialData` that needs to be set when the component mounts
  useEffect(() => {
    if (initialData.length > 0) {
      setRanges(initialData);
    }
  }, [initialData]);

  return (
    <div className="border border-gray-300 rounded-lg p-6 shadow-lg bg-white space-y-4">
  {ranges.map((range, index) => (
    <div key={index} className="flex flex-wrap items-center space-x-2">

      {/* Quantity Range Section */}
      <Select
        options={options}
        value={options.find(option => option.value === range.metric)}
        onChange={(selectedOption) => handleChange(selectedOption, index)}
        className="w-full sm:w-1/6 min-w-[80px]"
        placeholder="Metric"
      />

      <input
        type="number"
        value={range.min}
        onChange={(e) => handleRangeChange(index, 'min', e.target.value)}
        placeholder="Min Qty"
        className="w-full sm:w-1/6 min-w-[80px] px-2 py-1 border rounded"
      />

      <span className="text-gray-500 mx-1">to</span>

      <input
        type="number"
        value={range.max}
        onChange={(e) => handleRangeChange(index, 'max', e.target.value)}
        placeholder="Max Qty"
        className="w-full sm:w-1/6 min-w-[80px] px-2 py-1 border rounded"
      />

      {/* Pricing Section */}
      <span className="text-green-600 font-semibold mx-1">@ {currency}</span>

      <input
        type="number"
        value={range.price}
        onChange={(e) => handleRangeChange(index, 'price', e.target.value)}
        placeholder="Price"
        className="w-full sm:w-1/6 min-w-[80px] px-2 py-1 border rounded"
      />

      <div className="flex items-center justify-center bg-gray-200 text-gray-500 w-8 h-8 rounded-md">
        <FaTags />
      </div>

      <input
        type="number"
        value={range.discountPrice}
        onChange={(e) => handleRangeChange(index, 'discountPrice', e.target.value)}
        placeholder="Discount Price"
        className="w-full sm:w-1/6 min-w-[80px] px-2 py-1 border rounded"
      />

      {/* Actions */}
      <div className="flex items-center space-x-2 ml-2">
        {index !== 0 && (
          <button
            type="button"
            onClick={() => removeRange(index)}
            className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600"
            title="Remove Range"
          >
            <FaTrash />
          </button>
        )}
        {index === ranges.length - 1 && (
          <button
            type="button"
            onClick={addRange}
            className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
            title="Add Range"
          >
            <FaPlus />
          </button>
        )}
      </div>

      {/* Error Messages */}
      {errors && errors[index] && (
        <p className="text-red-500 mt-2 w-full text-center">
          {errors[index]}
        </p>
      )}
    </div>
  ))}
</div>
      
  );
};

export default QuantityPriceInput;
