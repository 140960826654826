import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import Select from 'react-select';


const PackagingInput = ({ onPackagingChange, currency, initialData }) => {
  const [packagingEntries, setPackagingEntries] = useState(
    initialData.length > 0 ? initialData : [{ type: '', size: '', price: '', min: '', max: '', metric: '' }]
  );

  useEffect(() => {
    if (initialData.length > 0) {
      setPackagingEntries(initialData);
    }
  }, [initialData]);

  const options = [
    { value: 'kilogram', label: 'Kg' },
    { value: 'pallet', label: 'Pallets' },
  ];

  const handleChange = (index, field, value) => {
    const updatedEntries = [...packagingEntries];
    updatedEntries[index][field] = value;
    setPackagingEntries(updatedEntries);

    const lastRange = updatedEntries[updatedEntries.length - 1];
    const allFieldsPopulated = Object.values(lastRange).every(val => val !== '');
    if (allFieldsPopulated) {
      onPackagingChange(updatedEntries);
    }
  };

  const handleMetricChange = (selectedOption, index) => {
    const updatedEntries = [...packagingEntries];
    updatedEntries[index].metric = selectedOption.value;
    setPackagingEntries(updatedEntries);
  };

  const addEntry = () => {
    setPackagingEntries([...packagingEntries, { type: '', size: '', price: '', min: '', max: '', metric: '' }]);
  };

  const removeEntry = (index) => {
    const updatedEntries = [...packagingEntries];
    updatedEntries.splice(index, 1);
    setPackagingEntries(updatedEntries);
  };

  return (
    <div className="border border-gray-200 rounded-lg p-4 shadow-md">
      <h3 className="text-lg font-bold mb-4">Packaging</h3>
      {packagingEntries.map((entry, index) => (
        <div key={index} className="flex flex-col md:flex-row items-center mb-4 space-y-2 md:space-y-0">
          <Select
            options={options}
            value={options.find(option => option.value === entry.metric)}
            onChange={(selectedOption) => handleMetricChange(selectedOption, index)}
            className="w-full md:w-1/6 mb-2 md:mb-0 md:mr-2"
            placeholder="Select Metric"
          />
          <input
            type="text"
            value={entry.type}
            onChange={(e) => handleChange(index, 'type', e.target.value)}
            placeholder="e.g. Branded Wooden Box"
            className="w-full md:w-1/6 px-3 py-2 border rounded md:mr-2"
          />
          <input
            type="number"
            value={entry.min}
            onChange={(e) => handleChange(index, 'min', e.target.value)}
            placeholder="Min Qty"
            className="w-full md:w-1/6 px-3 py-2 border rounded md:mr-2"
          />
          <div className="bg-gray-600 w-6 h-10 flex justify-center items-center text-white">-</div>
          <input
            type="number"
            value={entry.max}
            onChange={(e) => handleChange(index, 'max', e.target.value)}
            placeholder="Max Qty"
            className="w-full md:w-1/6 px-3 py-2 border rounded md:ml-2 md:mr-2"
          />
          <input
            type="text"
            value={entry.size}
            onChange={(e) => handleChange(index, 'size', e.target.value)}
            placeholder="Size"
            className="w-full md:w-1/6 px-3 py-2 border rounded md:mr-2"
          />
          <span className="text-green-600 font-bold md:mr-2">@ {currency}</span>
          <input
            type="number"
            value={entry.price}
            onChange={(e) => handleChange(index, 'price', e.target.value)}
            placeholder="Price"
            className="w-full md:w-1/6 px-3 py-2 border rounded md:mr-2"
          />
          
          <div className="flex items-center space-x-2 mt-2 md:mt-0">
            {index !== 0 && (
              <button
                type="button"
                onClick={() => removeEntry(index)}
                className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                <FaTrash />
              </button>
            )}
            <button
              type="button"
              onClick={addEntry}
              className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <FaPlus />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PackagingInput;
