import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import styles from '../../styles/styles'
import EventCard from "./EventCard";

const Events = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);

  return (
    <div>
      {
        !isLoading && (
          <div className={`${styles.section}`}>
            <div className={`${styles.heading}`}>
              <h1>Popular Events</h1>
            </div>

            <div className="w-full flex flex-col items-center py-6 px-4 md:px-8 lg:px-12">
              {allEvents.length !== 0 ? (
                <div className="w-full max-w-lg">
                  <EventCard data={allEvents[0]} />
                </div>
              ) : (
                <h4 className="text-lg text-gray-600 mt-6 text-center">
                  New Events Will Be Posted Soon!
                </h4>
              )}
            </div>


          </div>
        )
      }
    </div>
  )
}

export default Events