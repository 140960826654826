import React, { useEffect, useSyncExternalStore } from 'react'
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";
import { Helmet } from 'react-helmet';
import { trackPageView } from '../utils/trackPageEvent';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { analytics } from '../firebase'
import { loadUser } from '../redux/actions/user';


const HomePage = () => {

  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const userId = user ? user._id : null;
  const sellerId = seller ? seller._id : null;
  const dispatch=useDispatch();

  const location = useLocation();

  useEffect(() => {
    // Simulating user data
    const user = {
      userId:userId,
      sellerId:sellerId,
      isSuspended: false,
    };

    dispatch(loadUser(user));
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      setUserId(analytics, userId);
      setUserProperties(analytics, { sellerId });
    }
  }, [userId, sellerId]);

  // useEffect(() => {
  //   trackPageView(window.location.pathname, userId, sellerId);
  // }, []);

  return (
    <div>
      <Helmet>
        <title>Welcome to Indazetu — is Kenya's largest online marketplace that assists manufacturers, suppliers exporters to trade with each other at a common</title>
        <meta name="description" content="Discover Indazetu.com, poised to become Kenya's largest online marketplace. Connect with manufacturers, suppliers, and exporters effortlessly. Whether you're a buyer seeking quality products or a seller looking for new leads, Indazetu.com aims to be your trusted partner in trade. Explore our growing free business directory with listings of Kenyan and international companies. Join us as we build the future of Kenyan business connections." />
        <meta name="keywords" content="Discover Indazetu.com, poised to become Kenya's largest online marketplace. Kenya business directory, Kenyan manufacturers, suppliers in Kenya, exporters in Kenya, Kenyan marketplace, business listings Kenya, online business directory, B2B marketplace Kenya, free business listings, Kenyan companies, importers Kenya, exporters directory Kenya, Kenyan trade, Kenyan industrial products, manufacturers directory Kenya, business connections Kenya, Kenya business network, Nairobi business directory, Mombasa business listings, Thika companies, Kisumu manufacturers, online marketplace Kenya, African market, global suppliers Kenya,yellow pages" />
        <meta property="og:title" content="Welcome to Indazetu — is Kenya's largest online marketplace that assists manufacturers, suppliers exporters to trade with each other at a common" />
        <meta property="og:description" content="Connect with manufacturers, suppliers, and exporters effortlessly. Whether you're a buyer seeking quality products or a seller looking for new leads, Indazetu.com aims to be your trusted partner in trade. Explore our growing free business directory with listings of Kenyan and international companies. Join us as we build the future of Kenyan business connections." />
        <meta property="og:image" content="https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png/" />
        <meta property="og:url" content="https://indazetu.com/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://indazetu.com/" />
      </Helmet>
      <Header activeHeading={1} />
      <Hero />
      <Categories />
      {/* <Sponsored /> */}
      <BestDeals />
      <FeaturedProduct />
      <Events />
      <Footer />

    </div>
  )
}

export default HomePage