import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { HiArrowLeft, HiArrowRight, HiMiniMagnifyingGlassPlus } from 'react-icons/hi2';
import { BsCartPlus } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import MessageModal from "../Shop/MessageModal";
import useShop from "../../hooks/useShop";




const ProductDetails = ({ data }) => {

  console.log("Product Details", data);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const [minSampleQty, setMinSampleQty] = useState(0);
  const [maxSampleQty, setMaxSampleQty] = useState(0);
  const [samplePrice, setSamplePrice] = useState(0);
  const [sampleTotalPrice, setSampleTotalPrice] = useState(0);
  const [count, setCount] = useState(minSampleQty);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const [cartType, setCartType] = useState("product");
  const [showSamplePriceCalculation, setShowSamplePriceCalculation] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { shop, loading, error } = useShop(data.shopId);
  // shop=shop.shop;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };




  // Function to toggle the visibility of the sample product price calculation div
  const toggleSamplePriceCalculation = () => {
    setSampleTotalPrice(0);
    setSelectedRange('')
    setCount(0);
    setShowSamplePriceCalculation(!showSamplePriceCalculation);
    !showSamplePriceCalculation ? setCartType('sample') : setCartType('product');
  };

  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shopId));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);



  const incrementCount = () => {
    console.log('Increment', selectedRange, minSampleQty, maxSampleQty, samplePrice, 'count', count);

    if (count >= Number(minSampleQty) && count < Number(maxSampleQty)) {
      setCount(count + 1);
      setSampleTotalPrice(Number(count + 1) * Number(samplePrice))
      console.log('Increment1', selectedRange, minSampleQty, maxSampleQty);
    }

  };

  const decrementCount = () => {
    if (selectedRange) {
      console.log('decrement', selectedRange, minSampleQty, maxSampleQty, 'count', count);
      if (count > Number(minSampleQty) && count <= Number(maxSampleQty)) {
        setCount(count - 1);
        setSampleTotalPrice(Number(count - 1) * Number(samplePrice))
      }
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    console.log("already", id);
    let newData = { ...data };
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      newData.type = cartType;
      newData.selectedRange = selectedRange;
      newData.selectedRangeIndex = selectedRangeIndex;
      console.log("already", newData);
      if (newData.stock < 1) {
        toast.error("Product stock limited!");
      } else if (cart.length > 0 && shop?.shop?.currency !== cart[0]?.currency) {
        toast.error("Cant Add Different Currencies to Cart");
      } else {
        const cartData = { ...newData, qty: count, currency: shop?.shop?.currency };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  const handleMessageSubmit = () => {
    if (isAuthenticated) {
      const userId = user?._id;
      const sellerId = data?.shop?._id;

      if (userId && sellerId) {
        const seller = data.shop;

        navigate('/inbox', {
          state: { participants: `${userId}-${sellerId}`, recipient: seller, sender: user },
        });
      } else {
        console.error('User ID or Seller ID is missing');
      }
    } else {
      console.error('User is not authenticated');
    }
  };



  // Main Price
  // Safely accessing the priceInput and sorting prices
  const sortedPrices = data?.priceInput?.map(item => Number(item.price || item.discountPrice)).sort((a, b) => a - b) || [];
  console.log('priceInput', data?.priceInput);

  // Min order handling
  const sortedMinOrders = data?.priceInput?.map(item => Number(item.min)).sort((a, b) => a - b) || [];
  const minOrder = sortedMinOrders.length > 0 ?
    (Number.isInteger(sortedMinOrders[0]) ? sortedMinOrders[0] : sortedMinOrders[0].toFixed(2)) :
    0;  // Default to 0 if there are no min orders

  // Max order handling
  const sortedMaxOrders = data?.priceInput?.map(item => Number(item.max)).sort((a, b) => a - b) || [];
  const maxOrder = sortedMaxOrders.length > 0 ?
    (Number.isInteger(sortedMaxOrders[sortedPrices.length - 1]) ? sortedMaxOrders[sortedPrices.length - 1] : sortedMaxOrders[sortedPrices.length - 1].toFixed(2)) :
    0;  // Default to 0 if there are no max orders

  // Safely accessing samplePriceInput and sorting sample prices
  const sortedSamplePrices = data?.samplePriceInput?.map(item => Number(item.price || item.discountPrice)).sort((a, b) => a - b) || [];

  // Min sample order handling
  const sortedMinSampleOrders = data?.samplePriceInput?.map(item => Number(item.min)).sort((a, b) => a - b) || [];
  let minSampleOrder = 0;
  if (sortedMinSampleOrders.length > 0) {
    minSampleOrder = Number.isInteger(sortedMinSampleOrders[0]) ? sortedMinSampleOrders[0] : sortedMinSampleOrders[0].toFixed(2);
  } else {
    // Default to 0 if there are no min sample orders
    minSampleOrder = 0;
  }

  // Max sample order handling
  const sortedMaxSampleOrders = data?.samplePriceInput?.map(item => Number(item.max)).sort((a, b) => a - b) || [];
  const maxSampleOrder = sortedMaxSampleOrders.length > 0 ?
    (Number.isInteger(sortedMaxSampleOrders[sortedSamplePrices.length - 1]) ? sortedMaxSampleOrders[sortedSamplePrices.length - 1] : sortedMaxSampleOrders[sortedSamplePrices.length - 1].toFixed(2)) :
    0;  // Default to 0 if there are no max sample orders

  const [selectedRange, setSelectedRange] = useState('');
  const [selectedRangeIndex, setSelectedRangeIndex] = useState(0);



  // Function to handle range change
  const handleRangeChange = (range) => {
    console.log('range', range);
    const values = range.split('-');
    console.log('values', values);

    const minCount = parseInt(values[0], 10);
    const maxCount = parseInt(values[1], 10);
    const price = parseInt(values[2], 10);
    console.log('minCount', minCount);
    console.log('maxCount', maxCount);
    console.log('price', price);

    if (!range) {
      setSamplePrice(0);
      setSampleTotalPrice(0);
      return;
    }

    // If values are valid numbers
    if (!isNaN(minCount) && !isNaN(maxCount)) {
      setMinSampleQty(minCount);
      setCount(minCount);
      setMaxSampleQty(maxCount);
      setSamplePrice(price);
      setSampleTotalPrice(Number(price) * minCount);
      setSelectedRange(range); // Update selectedRange state
      setSelectedRangeIndex(Number(values[3]));
    } else {
      // Handle invalid range values
      console.error('Invalid range values');
    }
  };

  useEffect(() => {
    if (selectedRange) {
      handleRangeChange(selectedRange);
    }
  }, [selectedRange]); // Dependency array can be adjusted based on when you want to trigger the effect


  // Render quantity range options
  const renderRangeOptions = (data, type) => {
    console.log('render', type);
    if (type === 'sample') {
      console.log('priceInput22')
      return data?.samplePriceInput?.map((range, index) => (
        <option key={index} value={`${range.min} - ${range.max}-${range.discountPrice ? range.discountPrice : range.price}-${index}`} >
          {/* {`${range.min} - ${range.max}`} - {shop?.shop?.currency}{range.discountPrice ? range.discountPrice : range.price} */}
          {`${range.min} - ${range.max}`} {range.metric} @<small>{shop?.shop?.currency}.</small> {range.discountPrice ? range.discountPrice : range.price}
        </option>
      ));
    } else {
      console.log('priceInput')
      return data?.priceInput?.map((range, index) => (
        <option key={index} value={`${range.min} - ${range.max}-${range.discountPrice ? range.discountPrice : range.price}-${index}`} >
          {/* {`${range.min} - ${range.max}`} - {range.currency}${range.discountPrice ? range.discountPrice : range.price}/piece */}
          {`${range.min} - ${range.max}`} {range.metric} @<small>{shop?.shop?.currency}.</small> {range.discountPrice ? range.discountPrice : range.price}
        </option>
      ));
    }
  };
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (

    <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
            {/* {JSON.stringify(shop)} */}
            <div className="block w-full 800px:flex">

              <div className="w-full 800px:w-[50%]">
                <img
                  src={`${data && data.images[select]?.url}`}
                  alt=""
                  className="w-[80%]"
                />
                <div className="w-full flex">
                  {data &&
                    data.images.map((i, index) => (
                      <div
                        className={`${select === 0 ? "border" : "null"
                          } cursor-pointer`}
                      >
                        <img
                          src={`${i?.url}`}
                          alt=""
                          className="h-[200px] overflow-hidden mr-3 mt-3"
                          onClick={() => setSelect(index)}
                        />
                      </div>
                    ))}
                  <div
                    className={`${select === 1 ? "border" : "null"
                      } cursor-pointer`}
                  ></div>
                </div>
              </div>


              <div className="w-full 800px:w-[50%] pt-5">

                {!showSamplePriceCalculation && (
                  <>
                    {data?.samplePriceInput && data?.samplePriceInput?.length >0 ? (
                      <div className="flex justify-between items-center mt-4 mb-6">
                        <bPutton
                          className="flex items-center text-blue-600 hover:text-blue-800 font-medium transition-colors duration-200 ease-in-out"
                          onClick={toggleSamplePriceCalculation}
                        >
                          <HiMiniMagnifyingGlassPlus className="mr-2 text-orange-600" style={{ fontSize: '30px' }} />
                          <span className="text-base">Click To Purchase Sample</span>
                          <HiArrowRight className="ml-3" style={{ fontSize: '22px' }} />
                        </bPutton>
                      </div>
                    ) : (
                      <div className="text-red-500 text-lg font-semibold mt-4 mb-6">
                        <h2>Product has No Sample</h2>
                      </div>
                    )}

                    <h1 className={`${styles.productTitle} text-2xl font-bold text-gray-800 mt-5`}>{data.name}</h1>
                    <p className="text-gray-600 text-base mt-3 leading-relaxed">{data.description}</p>

                    <div className="flex pt-3">

                      {/* Select element and Add to Cart button on the left */}
                      <div className="flex flex-col justify-between ml-2 mr-12">
                        <div className="flex flex-wrap gap-4">
                          <select value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)} className="border border-gray-300 rounded-md px-4 py-2">
                            <option value="">Select Range</option>
                            {renderRangeOptions(data, 'product')}
                          </select>

                        </div>
                        <div className="flex items-center mt-4">
                          <button
                            className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                            onClick={decrementCount}
                          >
                            -
                          </button>
                          <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">{count}</span>
                          <button
                            className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                            onClick={incrementCount}
                          >
                            +
                          </button>

                        </div>
                        <br />

                        <Link to={`/shop/${data?.shopId}`} className="flex items-center space-x-2">
                          <img
                            src={`${shop?.shop?.avatar?.url}`}
                            alt=""
                            className="w-[50px] h-[50px] rounded-full"
                          />
                          <span className="flex items-center space-x-1">
                            <small>{`${shop?.shop?.name}`}</small>
                            {/* <span>Shop:</span> */}

                          </span>
                        </Link>

                        <div className="pr-8">
                          <Link to={`/shop/${data?.shopId}`}>
                            <h3 className={`${styles.shop_name} pb-1 pt-1`}>
                              {shop?.name}
                            </h3>
                            <h5 className="pb-3 text-[15px]">Total Cost: (@ {shop?.shop.currency} {Number(sampleTotalPrice)} ) </h5>
                            <h5 className="pb-3 text-[15px]">
                              ({averageRating}/5) Ratings
                            </h5>
                          </Link>
                        </div>
                      </div>
                      {/* Price list on the right */}
                      <div className="flex flex-wrap gap-2">
                        {/* {JSON.stringify(shop)} */}
                        {data?.priceInput?.map((option, index) => (
                          <div key={index} className="bg-[#FFD59A] shadow-md p-1 h-1/2 rounded-md ">
                            <h4 className="text-lg font-bold mb-2">{option.min} - {option.max} {option.metric}</h4>
                            <s className="text-red-600 mb-2"> Price: @{shop?.shop.currency} {option.price}</s>
                            <h2 className="text-blue-800 font-bold"> Discount Price: @{shop?.shop.currency} {option.discountPrice}</h2>
                          </div>
                        ))}
                      </div>

                    </div>
                    {/* Shop info */}
                    <div className="flex items-center pt-8">
                      {selectedRange ? <>
                        <div
                          className={`${styles.button} flex items-center space-x-2 bg-orange-400 cursor-pointer hover:bg-gray-300 p-2 text-white !rounded !h-11`}
                          onClick={() => addToCartHandler(data)}>
                          <BsCartPlus size={20} title="Add to cart" />
                          <span className="text-sm font-bold">Add To Cart</span>
                        </div>

                        <div className={`${styles.button} bg-[#6443d1] mt-4 !rounded !h-11 ml-4`} onClick={openModal}>
                          <span className="text-white flex items-center">Request Quote <AiOutlineMessage className="ml-1" /></span>
                        </div>
                        <MessageModal seller={data} type='productDetail' isOpen={isModalOpen} onClose={closeModal} />
                      </> : null
                      }
                    </div>

                  </>
                )}
                {/* show sample product calculation */}


                {showSamplePriceCalculation && (
                  <>

                    <div className="flex justify-between items-center mt-3 mb-5">
                      <span className="text-teal-500 flex items-center cursor-pointer hover:text-blue-700" onClick={toggleSamplePriceCalculation}>

                        <AiOutlineShoppingCart className="mr-1 text-orange-500 font-bold" style={{ fontSize: '34px' }} />
                        <span className="text-sm">Back To Purchase Product</span>
                        <HiArrowLeft className="ml-2" style={{ fontSize: '24px' }} />
                      </span>
                    </div>
                    <h1 className={`${styles.productTitle}`}>{data.name} <small className="text-orange-500">(SAMPLE PRODUCT)</small></h1>
                    <p>{data.description}</p>

                    <div className="flex pt-3">

                      {/* Select element and Add to Cart button on the left */}
                      <div className="flex flex-col justify-between ml-2 mr-12">
                        <div className="flex flex-wrap gap-4">
                          <select value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)} className="border border-gray-300 rounded-md px-4 py-2">
                            <option value="">Select Range</option>
                            {renderRangeOptions(data, 'sample')}
                          </select>
                        </div>
                        <div className="flex items-center mt-4">
                          <button
                            className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                            onClick={decrementCount}
                          >
                            -
                          </button>
                          <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">{count}</span>
                          <button
                            className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                            onClick={incrementCount}
                          >
                            +
                          </button>

                        </div>
                        <br />

                      </div>

                      {/* Price list on the right */}
                      <div className="flex flex-wrap gap-2">
                        {data?.samplePriceInput?.map((option, index) => (
                          <div key={index} className="bg-[#FFD59A] shadow-md p-1 rounded-md ">
                            <h4 className="text-lg font-bold mb-2">{option.min} - {option.max} {option.metric}</h4>
                            <s className="text-red-600 mb-2"> Price:@ {shop?.shop?.currency} {option.price}</s>
                            <h2 className="text-blue-800 font-bold"> Discount Price: @{shop?.shop?.currency} {option.discountPrice}</h2>

                          </div>
                        ))}
                      </div>

                    </div>

                    <div className="flex items-center pt-2">
                      <Link to={`/shop/${data?.shopId}`} className="flex items-center space-x-2">
                        <img
                          src={`${shop?.shop?.avatar?.url}`}
                          alt=""
                          className="w-[50px] h-[50px] rounded-full"
                        />
                        <span className="flex items-center space-x-1">
                          <small>{`${shop?.shop?.name}`}</small>
                          {/* <span>Shop:</span> */}

                        </span>
                      </Link>
                    </div>
                    <div className="pr-8">
                      <Link to={`/shop/${data?.shopId}`}>
                        <h3 className={`${styles.shop_name} pb-1 pt-1`}>
                          {shop?.name}
                        </h3>
                        <h5 className="pb-3 text-[15px]">Total Cost: (@ {shop?.shop.currency} {Number(sampleTotalPrice)} ) </h5>
                        <h5 className="pb-3 text-[15px]">
                          ({averageRating}/5) Ratings
                        </h5>
                      </Link>
                    </div>

                    <div className="flex items-center pt-8">
                      {selectedRange ? <>
                        <div
                          className={`${styles.button} flex items-center space-x-2 bg-orange-400 cursor-pointer hover:bg-gray-300 p-2 text-white !rounded !h-11`}
                          onClick={() => addToCartHandler(data)}>
                          <BsCartPlus size={20} title="Add to cart" />
                          <span className="text-sm font-bold">Add To Cart</span>
                        </div>

                        <div className={`${styles.button} bg-[#6443d1] mt-4 !rounded !h-11 ml-4`} onClick={openModal}>
                          <span className="text-white flex items-center">Request Quote <AiOutlineMessage className="ml-1" /></span>
                        </div>
                        <MessageModal seller={data?.shop} type='productDetail' isOpen={isModalOpen} onClose={closeModal} />
                      </> : null
                      }
                    </div>
                  </>
                )}
              </div>

            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            shop={shop}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
            minSampleOrder={minSampleOrder}
            maxSampleOrder={maxSampleOrder}
          />
          <br />
          <br />
        </div>
      ) : null
      }
    </div >
  );
};
const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
  minSampleOrder,
  maxSampleOrder,
  shop
}) => {
  const [active, setActive] = useState(1);


  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-between border-b pt-10 pb-2">
        {/* {JSON.stringify(shop)} */}
        <div className="relative">
          <h5
            className="text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
          {active === 1 && <div className={styles.active_indicator} />}
        </div>
        <div className="relative">
          <h5
            className="text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            onClick={() => setActive(2)}
          >
            Product Reviews
          </h5>
          {active === 2 && <div className={styles.active_indicator} />}
        </div>
        <div className="relative">
          <h5
            className="text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            onClick={() => setActive(3)}
          >
            Seller Information
          </h5>
          {active === 3 && <div className={styles.active_indicator} />}
        </div>
      </div>
      {active === 1 && (
        <>
          <p className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
            {data.description}
          </p>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex flex-wrap pb-3">
              <div className="flex items-center mr-4 mb-3">
                <span className="text-gray-600 text-sm mr-2">Color:</span>
                <div
                  className="w-6 h-6 mr-2 rounded-lg"
                  style={{ backgroundColor: data.selectedColor }}
                ></div>
                <span className="text-lg font-semibold">{data.selectedColor}</span>
              </div>
              <div className="flex items-center mb-3 ml-8">
                <span className="text-gray-600 text-sm mr-2">Stock:</span>
                <span className="text-lg font-semibold">{data.stock}</span>
              </div>
              <div className="flex items-center mb-3 ml-8">
                <span className="text-gray-600 text-sm mr-2">Sold:</span>
                <span className="font-[400] text-[17px] text-[#68d284]">
                  {data.sold_out} sold
                </span>
              </div>
            </div>

            <div className="flex justify-between pb-3">
              <div className="mr-2">
                <label className="pb-2 block text-sm font-medium text-gray-700">Min Order:</label>
                <span className="font-[400] text-[17px] text-[#68d284] pl-1">
                  {minSampleOrder} {data?.priceInput[0]?.metric}
                </span>
              </div>
              <div className="ml-2">
                <label className="pb-2 block text-sm font-medium text-gray-700">Max Order:</label>
                <span className="font-[400] text-[17px] text-[#68d284] pl-1">
                  {maxSampleOrder} {data?.priceInput[0]?.metric}
                </span>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Packaging Type</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Min-Max Qty</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.packaging?.map((pack, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">{pack.type}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{pack.size}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{pack.price}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{pack.min} - {pack.max} {pack.metric}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-wrap pt-4">
              <h2 className="text-lg font-semibold mr-2 mb-2">Tags:</h2>
              {data.tags.map((tag, index) => (
                <span
                  key={index}
                  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </>
      )}

      {active === 2 && (
        <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll">
          {data && data.reviews.map((item, index) => (
            <div className="w-full flex my-2" key={index}>
              <img
                src={`${item.user.avatar?.url}`}
                alt=""
                className="w-[50px] h-[50px] rounded-full"
              />
              <div className="pl-2">
                <div className="w-full flex items-center">
                  <h1 className="font-[500] mr-3">{item.user.name}</h1>
                  <Ratings rating={item.rating} />
                </div>
                <p>{item.comment}</p>
              </div>
            </div>
          ))}
          {data && data.reviews.length === 0 && (
            <div className="w-full flex justify-center">
              <h5>No Reviews for this product!</h5>
            </div>
          )}
        </div>
      )}

      {active === 3 && (
        <div className="w-full block 800px:flex p-5">
          <div className="w-full 800px:w-[50%]">
            <Link to={`/shop/${data.shopId}`}>
              <div className="flex items-center">
                <img
                  src={`${shop?.shop?.avatar?.url}`}
                  className="w-[50px] h-[50px] rounded-full"
                  alt=""
                />
                <div className="pl-3">
                  <h3 className={styles.shop_name}>{shop?.shop?.name}</h3>
                  <h5 className="pb-2 text-[15px]">({averageRating}/5) Ratings</h5>
                </div>
              </div>
            </Link>
            <p className="pt-2">{shop?.shop?.description}</p>
          </div>
          <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col items-end">
            <div className="text-left">
              <h5 className="font-[600]">
                Joined on:{" "}
                <span className="font-[500]">
                  {shop?.shop?.createdAt.slice(0, 10)}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Products:{" "}
                <span className="font-[500]">
                  {products.length}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Reviews:{" "}
                <span className="font-[500]">
                  {totalReviewsLength}
                </span>
              </h5>
              <Link to={`/shop/${data.shopId}`}>
                <div className={`${styles.button} !rounded-[4px] !h-[39.5px] mt-3`}>
                  <h4 className="text-white">Visit Shop</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductDetails;
