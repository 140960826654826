import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ProductDetails from "../components/Products/ProductDetails";
import SuggestedProduct from "../components/Products/SuggestedProduct";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const ProductDetailsPage = () => {
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const eventData = searchParams.get("isEvent");

  useEffect(() => {
    if (eventData !== null) {
      const data = allEvents && allEvents.find((i) => i._id === id);
      setData(data);
    } else {
      const data = allProducts && allProducts.find((i) => i._id === id);
      setData(data);
    }
  }, [allProducts, allEvents, id, eventData]);

  const getTitle = () => {
    return data ? `${data.name} - Buy Now at Indazetu` : 'Indazetu - Kenya\'s Largest Online Marketplace';
  };

  const getDescription = () => {
    if (data) {
      return `Discover ${data.name} at Indazetu. ${data.description ? data.description.substring(0, 160) : 'Connect with manufacturers, suppliers, and exporters effortlessly.'}`;
    }
    return 'Indazetu.com - Kenya\'s largest online marketplace. Connect with manufacturers, suppliers, and exporters effortlessly.';
  };

  const getKeywords = () => {
    if (data) {
      const baseKeywords = [
        `${data.name} bulk purchase`,
        `${data.name} bulk discount`,
        `buy ${data.name} in bulk`,
        `${data.name} wholesale`,
        `${data.name} bulk order`,
        `${data.name} bulk pricing`,
        `${data.category} bulk buy`,
        `${data.category} wholesale`,
        `discounted ${data.name} bulk`,
        `bulk buy ${data.name} deals`,
        `bulk purchase ${data.name} Kenya`,
        `wholesale ${data.name} suppliers`,
        `discounted ${data.name} suppliers`,
        `cheap ${data.name} in bulk`,
        `affordable ${data.name} wholesale`
      ];
      return baseKeywords.join(', ');
    }
    return 'Kenya business directory, Kenyan manufacturers, suppliers in Kenya, exporters in Kenya, Kenyan marketplace, business listings Kenya, online business directory, B2B marketplace Kenya, free business listings, Kenyan companies, importers Kenya, exporters directory Kenya, Kenyan trade, Kenyan industrial products, manufacturers directory Kenya, business connections Kenya, Kenya business network, Nairobi business directory, Mombasa business listings, Thika companies, Kisumu manufacturers, online marketplace Kenya, African market, global suppliers Kenya, yellow pages, bulk purchase discounts, wholesale discounts, bulk buy deals, bulk order savings, wholesale prices, bulk buying Kenya, bulk discounts Kenya, wholesale marketplace Kenya';
  };
  
  const getOgImage = () => {
    return data && data.images ? data?.images[0]?.url : 'https://indazetu.com/static/media/logoo-removebg-preview.041ee5fc1e771ab093a6.png';
  };

  return (
    <div>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta name="keywords" content={getKeywords()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta property="og:image" content={getOgImage()} />
        <meta property="og:url" content={`https://indazetu.com/product/${id}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`https://indazetu.com/product/${id}`} />
      </Helmet>
      <Header />
     
      {data && <ProductDetails data={data} />}
      {!eventData && data && <SuggestedProduct data={data} />}
      <Footer />
    </div>
  );
};
export default ProductDetailsPage;
