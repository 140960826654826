import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
// create product
export const createProduct =
  (
    name,
    description,
    category,
    tags,
    stock,
    shopId,
    images,
    priceInput,
    metrics,
    selectedColor,
    packaging,
    samplePriceInput,
    // minOrder,
    // maxOrder,
    isCartChecked,
    isContactChecked

  ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: "productCreateUpdateRequest",
        });

        const { data } = await axios.post(
          `${server}/product/create-product`,
          name,
          description,
          category,
          tags,
          stock,
          shopId,
          images,
          priceInput,
          metrics,
          selectedColor,
          packaging,
          samplePriceInput,
          // minOrder,
          // maxOrder,
          isCartChecked,
          isContactChecked

        );
        dispatch({
          type: "productCreateUpdateSuccess",
          payload: data.product,
        });
      } catch (error) {
        dispatch({
          type: "productCreateUpdateFail",
          payload: error.response.data.message,
        });
      }
    };

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};


// Backend: deleteProduct action
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteProductRequest",
    });

    const { data } = await axios.delete(
      `${server}/product/delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );

    // Log the response to check if the deletion was successful
    console.log('Response:', data);

    // If the response is successful, show the success toast
    toast.success(data.message);

    // Dispatch the success action
    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });

    // Return the data so that handleDelete can process it
    return data;

  } catch (error) {
    // Dispatch the failed action
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });

    // Show the error toast
    toast.error(error.response.data.message);

    // Throw the error so it can be caught by handleDelete
    throw error;
  }
};

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/get-all-products`);

    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};
export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: "updateProductRequest" });

    const { data } = await axios.put(`${server}/product/update-product/${id}`, productData);

    dispatch({
      type: "updateProductSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "updateProductFailed",
      payload: error.response.data.message,
    });
  }
};
export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getProductDetailsRequest" });

    const { data } = await axios.get(`${server}/product/get-product-details/${id}`);

    dispatch({
      type: "getProductDetailsSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "getProductDetailsFailed",
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// actions/productActions.js

export const createOrUpdateProduct = (productData, isUpdate = false, id) => async (dispatch) => {

  try {
    console.log('Sending product data:', productData);
    
    const { data } = isUpdate
      ? await axios.put(`${server}/product/update-product/${id}`, productData)
      : await axios.post(`${server}/product/create-product`, productData);
    
    console.log('API response data:', data);
    
    dispatch({
      type: isUpdate ? "productUpdateSuccess" : "productCreateSuccess",
      payload: data,
    });
  } catch (error) {
    console.error('Error during API call:', error);
    dispatch({
      type: isUpdate ? "productUpdateFail" : "productCreateFail",
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
  
};

