import React, { useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import useShop from "../../../hooks/useShop";


const ProductCard = ({ data, isEvent }) => {

  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { shop, loading, error } = useShop(data.shopId);
  console.log('rendered', data?.priceInput[0].discountPrice);
  useEffect(() => {

    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    console.log('wishlist', data);

    dispatch(addToWishlist({ ...data, currency: shop?.shop?.currency }));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    // const isItemExists = cart && cart.find((i) => i._id === id);

    console.log('serkali', cart);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else if (cart.length > 0 && shop?.shop?.currency !== cart[0]?.currency) {
        toast.error("Cant Add Different Currencies to Cart");
      }
      else {

        const cartData = { ...data, qty: 1, currency: shop?.shop?.currency };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };
// Format the price to show integers without decimals and non-integers with two decimal places
const formatPrice = (price) => Number.isInteger(price) ? price : price.toFixed(2);

// Sort and map price values
const sortedPrices = data?.priceInput.map(item => Number(item.price || item.discountPrice)).sort((a, b) => a - b);

// Get the smallest and largest prices and format them
const smallestPrice = formatPrice(sortedPrices[0]);
const largestPrice = formatPrice(sortedPrices[sortedPrices.length - 1]);

// Sort and map min order values
const sortedOrders = data?.priceInput.map(item => Number(item.min)).sort((a, b) => a - b);

// Format the minimum order
const minOrder = formatPrice(sortedOrders[0]);

// Sort and map max order values
const sortedMaxOrders = data?.priceInput?.map(item => Number(item.max)).sort((a, b) => a - b);

// Format the maximum order if it exists
const maxOrder = sortedMaxOrders.length > 0
  ? formatPrice(sortedMaxOrders[sortedMaxOrders.length - 1])
  : 0;
  // Default to 0 if there are no max orders
  // console.log('min order', minOrder);
  return (
    <div className="w-full h-[380px] bg-white rounded-lg shadow-md p-4 relative cursor-pointer hover:shadow-lg transition-shadow duration-300">
      <Link to={`${isEvent ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`} className="block">
        <img
          src={`${data.images && data.images[0]?.url}`}
          alt={data.name}
          className="w-full h-[180px] object-cover rounded-t-lg"
        />
      </Link>

      <div className="mt-3">
        <Link to={`/shop/${shop?._id}`}>
          <p className="text-sm text-gray-500 font-medium">{shop?.name}</p>
        </Link>
        <Link to={`${isEvent ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <h4 className="font-bold text-lg mb-1 text-gray-800">
            {data.name.length > 40 ? `${data.name.slice(0, 40)}...` : data.name}
          </h4>
        </Link>

        {/* Pricing Section */}
        <div className="mt-3">
          <div className="flex items-center justify-between">
            {/* Price */}
            <span className="font-semibold text-red-500">
              {shop?.shop?.currency} {smallestPrice === largestPrice ? smallestPrice : `${smallestPrice} - ${largestPrice}`}
            </span>
          </div>

          <div className="flex items-center justify-between mt-1">
            {/* Minimum Order */}
            <span className="text-gray-600 text-sm">
              Min Order: {minOrder} {data?.priceInput[0]?.metric}
            </span>
            {/* Maximum Order */}
            <span className="text-gray-600 text-sm">
              Max Order: {maxOrder} {data?.priceInput[0]?.metric}
            </span>
          </div>
        </div>

      </div>

      {/* Action Icons */}
      <div className="absolute top-3 right-3 flex flex-col items-center space-y-2">
        {click ? (
          <AiFillHeart
            size={22}
            className="cursor-pointer text-red-500"
            onClick={() => removeFromWishlistHandler(data)}
            title="Remove from wishlist"
          />
        ) : (
          <AiOutlineHeart
            size={22}
            className="cursor-pointer text-gray-400 hover:text-red-500"
            onClick={() => addToWishlistHandler(data)}
            title="Add to wishlist"
          />
        )}
        <Link to={`/product/${data._id}`}>
          <AiOutlineEye
            size={22}
            className="cursor-pointer text-gray-400 hover:text-blue-500"
            title="Quick view"
          />
        </Link>
        <AiOutlineShoppingCart
          size={25}
          className="cursor-pointer text-gray-400 hover:text-green-500"
          onClick={() => addToCartHandler(data._id)}
          title="Add to cart"
        />
      </div>
    </div>
  );

};

export default ProductCard;
