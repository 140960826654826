import React, { useState, useEffect } from 'react';
import { FaTrashAlt, FaPlus } from 'react-icons/fa';
const ColorPicker = ({ defaultColors = [], onColorChange }) => {
  const [colors, setColors] = useState(defaultColors);

  useEffect(() => {
    if (defaultColors.length) {
      setColors(defaultColors);
    }
  }, [defaultColors]);

  const handleColorChange = (index, newColor) => {
    const updatedColors = [...colors];
    updatedColors[index] = newColor; // Update the specific color at the index
    setColors(updatedColors);
    onColorChange(updatedColors); // Pass the updated colors array to the parent component
  };

  const handleAddColor = () => {
    setColors([...colors, '#000000']); // Add a new default black color
  };

  const handleRemoveColor = (index) => {
    const updatedColors = colors.filter((_, i) => i !== index); // Remove the selected color
    setColors(updatedColors);
    onColorChange(updatedColors);
  };

  return (
    <div>
      <div className="flex flex-wrap space-x-4 mb-4">
        {colors?.map((color, index) => (
         <div
         key={index}
         className="flex items-center space-x-4 p-3 mb-3 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow"
       >
      
            <div>
            <input
              type="color"
              value={color}
              onChange={(e) => handleColorChange(index, e.target.value)}
              className="border border-gray-300 rounded-[3px] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              style={{ width: '40px', height: '40px' }} // Adjust size if needed
            />
            <br/>
            {/* Display color code (name) next to the color picker */}
            <span className="text-sm">{color}</span>
            </div>
            <button
              type="button"
              onClick={() => handleRemoveColor(index)}
              className="bg-red-500 text-white p-2 rounded hover:bg-red-600 transition-colors"
            >
           <small><FaTrashAlt/></small>
            </button>
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={handleAddColor}
        className="bg-blue-500 text-white p-2 rounded"
      >
     <FaPlus/>
      </button>
    </div>
  );
};

export default ColorPicker;
